import { useDesignComponents } from '@hooks/use-design-components';

import { Design } from '@type/harmony-config';
import { useRouter } from 'next/router';
import { ComponentType, useEffect } from 'react';
import FloatingMenuA from './designA/floating-menu';
import FloatingMenuB from './designB/floating-menu';
import FloatingMenuC from './designC/floating-menu';
import { MobileWebviewService } from '@services/mobile-webview.service';

export interface InternalFloatingMenuProps {
    pathname: string;
}

const FloatingMenu: React.FC = () => {
    const Component = useDesignComponents<ComponentType<InternalFloatingMenuProps>>({
        [Design.DesignA]: FloatingMenuA,
        [Design.DesignB]: FloatingMenuB,
        [Design.DesignC]: FloatingMenuC,
    });
    const { pathname, query } = useRouter();
    const isCustomPage = pathname === '/[...page]';
    const customPageQuery = `/${query.page?.[0]}`;

    useEffect(() => {
        MobileWebviewService.sendIsBottomBarOpen(true);
        return () => {
            MobileWebviewService.sendIsBottomBarOpen(false);
        };
    }, []);

    return <Component pathname={isCustomPage ? customPageQuery : pathname} />;
};

export default FloatingMenu;
