import { useDesignComponents } from '@hooks/use-design-components';
import { useHarmony } from '@hooks/use-harmony';
import { Design } from '@type/harmony-config';
import React from 'react';
import TopButtonA from './designA/top-button';
import TopButtonB from './designB/top-button';
import TopButtonC from './designC/top-button';

export interface TopButtonProps {
    isVisible: boolean;
    bottom: string;
    children: React.ReactNode;
    role: string;
    onClick: () => void;
}

const TopButton: React.FC<TopButtonProps> = (props) => {
    const { theme } = useHarmony();

    const Component = useDesignComponents<typeof TopButtonA | typeof TopButtonB | typeof TopButtonC>({
        [Design.DesignA]: TopButtonA,
        [Design.DesignB]: TopButtonB,
        [Design.DesignC]: TopButtonC,
    });

    return <Component theme={theme} {...props} />;
};

export default TopButton;
