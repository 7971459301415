import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from './use-previous';
import { useScrollY } from './use-scroll-y';

/**
 * Returns a boolean indicating whether the user is scrolling up or down.
 * @default true
 */
export function useIsScrollingUp(): boolean {
    const [isScrollingUp, setIsScrollingUp] = useState<boolean>(true);
    const scrollY = useScrollY();
    const previousScrollY = usePrevious(scrollY);

    useEffect(() => {
        const previous = previousScrollY || 0;
        if (isScrollingUp && scrollY > previous) {
            setIsScrollingUp(false);
        } else if (!isScrollingUp && scrollY < previous) {
            setIsScrollingUp(true);
        }
    }, [previousScrollY]);

    return useMemo<boolean>(() => isScrollingUp, [isScrollingUp]);
}
