import { Box, HStack, forwardRef } from '@chakra-ui/react';
import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { AvailableCouponIndicatorInnerProps } from '..';
import { useTranslations } from 'next-intl';

const AvailableCouponIndicatorC: React.FC<AvailableCouponIndicatorInnerProps> = forwardRef<AvailableCouponIndicatorInnerProps, 'button'>((props, ref) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Box
            p="4px 12px"
            bgColor={theme.black}
            borderRadius="full"
            pos="absolute"
            right="-11.5px"
            bottom="55px"
            w="max-content"
            filter="drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.20))"
            {...props}
            ref={ref}
        >
            <HStack spacing="4px">
                <BodyText size="16px" lineHeight="20px" color={theme.text.white}>
                    {t('coupon.availableCoupon')}
                </BodyText>
                <IconButton onClick={props.onClose} size={20} color={theme.white}>
                    close
                </IconButton>
            </HStack>
            <Box zIndex={-1} bgColor={theme.black} w="11px" h="11px" pos="absolute" right="36px" bottom="-5px" transform="auto" rotate="45deg" />
        </Box>
    );
});

export default AvailableCouponIndicatorC;
