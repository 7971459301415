import safeWindow from '@services/safe-window.service';
import { useEffect, useState } from 'react';

export const useScrollY = () => {
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        function handleScroll() {
            setScrollY(window.scrollY);
        }

        if (safeWindow) {
            safeWindow.addEventListener('scroll', handleScroll, { passive: true });
        }

        return () => {
            if (safeWindow) {
                safeWindow.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    return scrollY;
};
