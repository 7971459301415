import { useScrollY } from '@hooks/use-scroll-y';
import { FixedContainer } from '@styles/styled-components';
import CustomIcon from './custom-icon';
import TopButton from '@legacy/designs/top-button';
import { useExtension } from '@hooks/use-extension';

interface TopProps {
    bottom?: string;
}

const Top: React.FC<TopProps> = ({ bottom }) => {
    const scrollY = useScrollY();
    const { extensionStatus } = useExtension();

    return (
        <FixedContainer maxScreenWidth={extensionStatus?.maxScreenWidth ?? 0}>
            <TopButton role="button" bottom={bottom || '80px'} isVisible={scrollY > 50} onClick={() => scrollTo({ top: 0, behavior: 'smooth' })}>
                <CustomIcon>expand_less</CustomIcon>
            </TopButton>
        </FixedContainer>
    );
};

export default Top;
