import { Center } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { useIsScrollingUp } from '@hooks/use-is-scrolling-up';
import { FixedContainer } from '@styles/styled-components';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import Top from 'src/legacy/components/common/top';
import RoutesEnum from 'src/routes/routes';
import { InternalFloatingMenuProps } from '../floating-menu';

const FloatingMenuA: React.FC<InternalFloatingMenuProps> = ({ pathname }) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const isScrollingUp = useIsScrollingUp();

    return (
        <div>
            <Top bottom={isScrollingUp ? '80px' : '22px'} />
            <FixedContainer maxScreenWidth={extensionStatus?.maxScreenWidth ?? 0}>
                <div
                    css={css`
                        width: 100%;
                        padding: 8px 10px 22px 10px;
                        background-color: transparent;
                        z-index: ${ThemeUtils.zIndex.above};
                        max-width: 860px;
                        opacity: ${isScrollingUp ? 1 : 0};
                        pointer-events: ${isScrollingUp ? 'auto' : 'none'};
                        transition: opacity 200ms ease-in-out;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 48px;
                            border-radius: 53px;
                            background-color: ${theme.primary[600]};
                            box-shadow: ${theme.dropShadow.lg};
                        `}
                    >
                        <Center flex={1}>
                            <Link href={RoutesEnum.Category}>
                                <FloatingIconButton active={pathname === RoutesEnum.Category || pathname === RoutesEnum.CategoryDetail} size="30px" color={theme.white}>
                                    menu
                                </FloatingIconButton>
                            </Link>
                        </Center>
                        <VerticalDivider />
                        <Center flex={1}>
                            <Link href={RoutesEnum.Home}>
                                <FloatingIconButton active={pathname === RoutesEnum.Home} size="30px" color={theme.white} className="home">
                                    home
                                </FloatingIconButton>
                            </Link>
                        </Center>
                        {extensionStatus?.search.isEnabled && (
                            <>
                                <VerticalDivider />
                                <Center flex={1}>
                                    <Link href={RoutesEnum.Search}>
                                        <FloatingIconButton active={pathname === RoutesEnum.Search} size="30px" color={theme.white} className="product-search">
                                            search
                                        </FloatingIconButton>
                                    </Link>
                                </Center>
                            </>
                        )}
                    </div>
                </div>
            </FixedContainer>
        </div>
    );
};

export default FloatingMenuA;

const VerticalDivider = styled.div`
    width: 1px;
    height: 17px;
    background-color: rgba(255, 255, 255, 0.4);
`;

const FloatingIconButton = styled(CustomIconButton)<{ active: boolean }>`
    ${({ active }) => `
        opacity: ${active ? 1 : 0.4};
    `}
`;
