import AvailableCouponIndicator from '@designs/primitives/main/bottom-menu/available-coupon-indicator';
import styled from '@emotion/styled';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { useIsScrollingUp } from '@hooks/use-is-scrolling-up';
import { FixedContainer } from '@styles/styled-components';
import { IThemeProps } from '@type/common';
import ThemeUtils from '@utils/theme.utils';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import CustomIcon from 'src/legacy/components/common/custom-icon';
import BodyText from 'src/legacy/components/common/text/body-text';
import Top from 'src/legacy/components/common/top';
import RoutesEnum from 'src/routes/routes';
import { InternalFloatingMenuProps } from '../floating-menu';

const FloatingMenuC: React.FC<InternalFloatingMenuProps> = ({ pathname }) => {
    const { theme } = useHarmony();
    const t = useTranslations();
    const isScrollingUp = useIsScrollingUp();
    const { isAuth } = useCustomer();
    const { extensionStatus } = useExtension();

    return (
        <>
            <Top bottom={isScrollingUp ? '20px' : '90px'} />
            <FixedContainer maxScreenWidth={extensionStatus?.maxScreenWidth ?? 0}>
                <Menu theme={theme}>
                    <Link href={RoutesEnum.Home}>
                        <FloatingIconButton>
                            <CustomIcon size="25px" color={pathname === RoutesEnum.Home ? theme.primary.default : theme.gray[600]} fill={pathname === RoutesEnum.Home}>
                                home
                            </CustomIcon>
                            <BodyText size="14px" color={pathname === RoutesEnum.Home ? theme.primary.default : theme.gray[600]}>
                                {t('common.home')}
                            </BodyText>
                        </FloatingIconButton>
                    </Link>
                    <Link href={RoutesEnum.Category}>
                        <FloatingIconButton>
                            <CustomIcon size="25px" color={pathname === RoutesEnum.Category ? theme.primary.default : theme.gray[800]}>
                                sort
                            </CustomIcon>
                            <BodyText size="14px" color={pathname === RoutesEnum.Category ? theme.primary.default : theme.gray[600]}>
                                {t('category.categories')}
                            </BodyText>
                        </FloatingIconButton>
                    </Link>
                    <Link href={RoutesEnum.Event}>
                        <FloatingIconButton>
                            <CustomIcon size="25px" color={pathname === RoutesEnum.Event ? theme.primary.default : theme.gray[800]}>
                                event_available
                            </CustomIcon>
                            <BodyText size="14px" color={pathname === RoutesEnum.Event ? theme.primary.default : theme.gray[600]}>
                                {t('home.event')}
                            </BodyText>
                        </FloatingIconButton>
                    </Link>
                    <Link href={isAuth ? RoutesEnum.My : RoutesEnum.Login}>
                        <FloatingIconButton>
                            <CustomIcon size="25px" color={pathname === RoutesEnum.My ? theme.primary.default : theme.gray[800]} fill={pathname === RoutesEnum.My}>
                                person
                            </CustomIcon>
                            <BodyText size="14px" color={pathname === RoutesEnum.My ? theme.primary.default : theme.gray[600]}>
                                {t('home.mypage')}
                            </BodyText>
                            <AvailableCouponIndicator />
                        </FloatingIconButton>
                    </Link>
                </Menu>
            </FixedContainer>
        </>
    );
};

export default FloatingMenuC;

const Menu = styled.div<IThemeProps>`
    ${({ theme }) => `
        position: fixed;
        width: 100%;
        padding: 13px 30px 22px;
        left: 0px;
        bottom: 0px;
        background-color: white;
        display: flex;
        justify-content: space-between;
        z-index: ${ThemeUtils.zIndex.above};
        border-top: 1px solid ${theme.gray['50']};
    `}
`;

const FloatingIconButton = styled.div`
    position: relative;
    width: 60px;
    height: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
`;
