import { Box, HStack, forwardRef } from '@chakra-ui/react';
import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import ThemeUtils from '@utils/theme.utils';
import { useInView } from 'react-cool-inview';
import { AvailableCouponIndicatorInnerProps } from '..';
import { useTranslations } from 'next-intl';

const AvailableCouponIndicatorA: React.FC<AvailableCouponIndicatorInnerProps> = forwardRef<AvailableCouponIndicatorInnerProps, 'button'>((props) => {
    const { theme } = useHarmony();
    const { inView, observe } = useInView({ rootMargin: '-46px' });
    const t = useTranslations();

    return (
        <Box
            p="4px 8px"
            bgColor={theme.secondary.default}
            pos="absolute"
            top="46px"
            right="22px"
            zIndex={ThemeUtils.zIndex.floating}
            opacity={inView ? 1 : 0}
            transition={'opacity 200ms ease-in-out'}
            pointerEvents={inView ? 'auto' : 'none'}
            {...props}
            ref={observe}
        >
            <HStack spacing="4px" color={ThemeUtils.isColorLight(theme.secondary.default) ? theme.black : theme.white}>
                <BodyText color={'inherit'} size="16px" lineHeight="1" weight="bold">
                    {t('coupon.availableCoupon')}
                </BodyText>
                <IconButton onClick={props.onClose} size={20} color={'inherit'}>
                    close
                </IconButton>
            </HStack>
            <Box zIndex={-1} bgColor={theme.secondary.default} w="11px" h="11px" pos="absolute" right="7px" top="-5px" transform="auto" rotate="45deg" />
        </Box>
    );
});

export default AvailableCouponIndicatorA;
