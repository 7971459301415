import AvailableCouponIndicator from '@designs/primitives/main/bottom-menu/available-coupon-indicator';
import styled from '@emotion/styled';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useExtension } from '@hooks/use-extension';
import { useHarmony } from '@hooks/use-harmony';
import { IThemeProps, ScreenSizeProps } from '@type/common';
import DomainUtils from '@utils/domain.utils';
import ThemeUtils from '@utils/theme.utils';
import Link from 'next/link';
import CustomIconButton from 'src/legacy/components/common/custom-icon-button';
import RoutesEnum from 'src/routes/routes';
import { InternalFloatingMenuProps } from '../floating-menu';

const FloatingMenuB: React.FC<InternalFloatingMenuProps> = ({ pathname }) => {
    const { theme } = useHarmony();
    const { extensionStatus } = useExtension();
    const { isAuth } = useCustomer();

    return (
        <Menu theme={theme} maxScreenWidth={extensionStatus?.maxScreenWidth}>
            <Link href={RoutesEnum.Home}>
                <FloatingIconButton
                    theme={theme}
                    active={pathname === RoutesEnum.Home}
                    fill
                    size="30px"
                    color={pathname === RoutesEnum.Home ? theme.primary.default : theme.gray[200]}
                    className="home"
                >
                    home
                </FloatingIconButton>
            </Link>
            {extensionStatus?.search.isEnabled && (
                <Link href={RoutesEnum.Search}>
                    <FloatingIconButton
                        theme={theme}
                        active={pathname === RoutesEnum.Search}
                        size="30px"
                        color={pathname === RoutesEnum.Search ? theme.primary.default : theme.gray[200]}
                        className="product-search"
                    >
                        search
                    </FloatingIconButton>
                </Link>
            )}
            {/* TODO: temp hiding for NHPay */}
            {/* <HStack align="center" alignItems="center">
                    <Link href={RoutesEnum.Coupons}>
                        <FloatingIconButton
                            theme={theme}
                            active={pathname === RoutesEnum.Coupons}
                            size="30px"
                            color={pathname === RoutesEnum.Coupons ? theme.primary.default : theme.grayscale[200]}
                        >
                            confirmation_number
                        </FloatingIconButton>
                    </Link>
                </HStack> */}
            {!(DomainUtils.isNhPay || DomainUtils.isDmarket) && (
                <Link href={RoutesEnum.Category}>
                    <FloatingIconButton
                        theme={theme}
                        active={pathname === RoutesEnum.Category}
                        fill
                        size="30px"
                        color={pathname === RoutesEnum.Category ? theme.primary.default : theme.gray[200]}
                    >
                        menu
                    </FloatingIconButton>
                </Link>
            )}
            <Link href={isAuth ? RoutesEnum.My : RoutesEnum.Login}>
                <FloatingIconButtonContainer>
                    <FloatingIconButton
                        theme={theme}
                        active={pathname === RoutesEnum.My}
                        fill
                        size="30px"
                        color={pathname === RoutesEnum.My ? theme.primary.default : theme.gray[200]}
                    >
                        person
                    </FloatingIconButton>
                    <AvailableCouponIndicator />
                </FloatingIconButtonContainer>
            </Link>
        </Menu>
    );
};

export default FloatingMenuB;

const Menu = styled.nav<ScreenSizeProps>`
    position: fixed;
    transform: none;
    bottom: 0px;
    width: 100%;
    max-width: ${({ maxScreenWidth }) => `min(100vw, ${maxScreenWidth}px)`};
    height: 60px;
    padding-left: 46px;
    padding-right: 46px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    z-index: ${ThemeUtils.zIndex.above};
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.12));
    border-radius: 16px 16px 0 0;
`;

const FloatingIconButtonContainer = styled.div`
    position: relative;
`;

const FloatingIconButton = styled(CustomIconButton)<IThemeProps & { active: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;

    ${({ active, theme }) =>
        active
            ? `
        > span {filter: drop-shadow(0px 0px 6px ${theme.primary[400]});}
    `
            : ''}
`;
