import { useEffect, useRef } from 'react';

type UsePreviousOptions = {
    /**
     * Tells the hook to keep the existing value if undefined is passed in as a value.
     */
    maintainValue?: boolean;
};

export function usePrevious<T>(value: T, options?: UsePreviousOptions) {
    const ref = useRef<T>();
    useEffect(() => {
        if ((options?.maintainValue && value !== undefined) || !options?.maintainValue) {
            ref.current = value;
        }
    });
    return ref.current;
}
