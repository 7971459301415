import { Box, HStack, forwardRef } from '@chakra-ui/react';
import IconButton from '@designs/primitives/common/icon-button';
import Body2C from '@designs/primitives/typography/body-2-c';
import { useHarmony } from '@hooks/use-harmony';
import ThemeUtils from '@utils/theme.utils';
import { AvailableCouponIndicatorInnerProps } from '..';
import { useTranslations } from 'next-intl';

const AvailableCouponIndicatorD: React.FC<AvailableCouponIndicatorInnerProps> = forwardRef<AvailableCouponIndicatorInnerProps, 'button'>((props, ref) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Box
            p="4px 8px"
            bgColor={theme.secondary.default}
            boxShadow={theme.dropShadow.dropdown}
            pos="absolute"
            right="-19px"
            bottom="55px"
            zIndex={ThemeUtils.zIndex.above}
            {...props}
            ref={ref}
        >
            <HStack spacing="4px" color={ThemeUtils.isColorLight(theme.secondary.default) ? theme.black : theme.white}>
                <Body2C> {t('coupon.availableCoupon')}</Body2C>
                <IconButton onClick={props.onClose} size={20}>
                    close
                </IconButton>
            </HStack>
            <Box zIndex={-1} bgColor="inherit" w="11px" h="11px" pos="absolute" right="46px" bottom="-5px" transform="auto" rotate="45deg" />
        </Box>
    );
});

export default AvailableCouponIndicatorD;
