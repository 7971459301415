import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { TopButtonProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const TopButtonC = styled.div<IThemeProps & TopButtonProps>`
    ${({ theme, isVisible, bottom }) => `
        position: absolute;
        right: 16px;
        bottom: ${bottom};
        z-index: ${ThemeUtils.zIndex.floating};
        width: 40px;
        height: 40px;
        opacity: ${isVisible ? 1 : 0};
        pointer-events: ${isVisible ? 'auto' : 'none'};
        transition: all 200ms ease-in-out;
        background: white;
        border-radius: 50%;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: ${theme.gray[800]};
    `}
`;

export default TopButtonC;
