import { Box, HStack, forwardRef } from '@chakra-ui/react';
import IconButton from '@designs/primitives/common/icon-button';
import { useHarmony } from '@hooks/use-harmony';
import BodyText from '@legacy/components/common/text/body-text';
import { AvailableCouponIndicatorInnerProps } from '..';
import { useTranslations } from 'next-intl';

const AvailableCouponIndicatorB: React.FC<AvailableCouponIndicatorInnerProps> = forwardRef<AvailableCouponIndicatorInnerProps, 'button'>((props, ref) => {
    const { theme } = useHarmony();
    const t = useTranslations();

    return (
        <Box
            p="4px 6px 4px 10px"
            bgColor={theme.primary.lighten[200]}
            boxShadow={'0px 0px 3px 0px rgba(0, 0, 0, 0.20)'}
            borderRadius="6px"
            border={`1px solid ${theme.primary.default}`}
            pos="absolute"
            right="-15px"
            bottom="55px"
            w="max-content"
            {...props}
            ref={ref}
        >
            <HStack spacing="4px" color={theme.primary.default}>
                <BodyText size="16px" color="inherit" weight="bold" lineHeight="18px">
                    {t('coupon.availableCoupon')}
                </BodyText>
                <IconButton onClick={props.onClose} size={20}>
                    close
                </IconButton>
            </HStack>
            <Box
                zIndex={1}
                bgColor={'inherit'}
                borderBottom={`1px solid ${theme.primary.default}`}
                borderRight={`1px solid ${theme.primary.default}`}
                borderBottomRightRadius={'3px'}
                w="11px"
                h="11px"
                pos="absolute"
                right="39px"
                bottom="-6px"
                transform="auto"
                rotate="45deg"
            />
        </Box>
    );
});

export default AvailableCouponIndicatorB;
