import styled from '@emotion/styled';
import { IThemeProps } from '@type/common';
import { TopButtonProps } from '..';
import ThemeUtils from '@utils/theme.utils';

const TopButtonB = styled.div<IThemeProps & TopButtonProps>`
    ${({ theme, isVisible, bottom }) => `
        position: absolute;
        right: 16px;
        bottom: ${bottom};
        z-index: ${ThemeUtils.zIndex.floating};
        width: 48px;
        height: 48px;
        opacity: ${isVisible ? 0.8 : 0};
        pointer-events: ${isVisible ? 'auto' : 'none'};
        transition: all 200ms ease-in-out;
        background: white;
        border-radius: 50%;
        border: solid 1px ${theme.gray[600]};
        box-shadow: ${theme.dropShadow.lg};
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: ${theme.gray[800]};
    `}
`;

export default TopButtonB;
