import { useDesignComponents } from '@hooks/use-design-components';

import { BoxProps } from '@chakra-ui/react';
import useCouponCount from '@hooks/use-coupon-count';
import { useCustomer } from '@hooks/use-customer/use-customer';
import { useExtension } from '@hooks/use-extension';
import { CouponStatusEnum } from '@models/coupon/requestDto/query-coupon.dto';
import StorageService from '@services/storage/storage.service';
import { Design } from '@type/harmony-config';
import DateUtils from '@utils/date.utils';
import { ComponentType, MouseEventHandler, useEffect, useState } from 'react';
import AvailableCouponIndicatorA from './designA/available-coupon-indicator';
import AvailableCouponIndicatorB from './designB/available-coupon-indicator';
import AvailableCouponIndicatorC from './designC/available-coupon-indicator';
import AvailableCouponIndicatorD from './designD/available-coupon-indicator';

export interface AvailableCouponIndicatorProps extends BoxProps {}

export interface AvailableCouponIndicatorInnerProps extends AvailableCouponIndicatorProps {
    onClose: MouseEventHandler<HTMLButtonElement>;
}

const AvailableCouponIndicator: React.FC<AvailableCouponIndicatorProps> = (props) => {
    const Component = useDesignComponents<ComponentType<AvailableCouponIndicatorInnerProps>>({
        [Design.DesignA]: AvailableCouponIndicatorA,
        [Design.DesignB]: AvailableCouponIndicatorB,
        [Design.DesignC]: AvailableCouponIndicatorC,
        [Design.DesignD]: AvailableCouponIndicatorD,
    });

    const [isOpened, setIsOpened] = useState<boolean>(false);
    const { isAuth } = useCustomer();
    const { extensionStatus } = useExtension();

    const couponTotal = useCouponCount({ status: [CouponStatusEnum.Available] }, isAuth && extensionStatus?.coupon.isEnabled);

    const handleClose: AvailableCouponIndicatorInnerProps['onClose'] = (e) => {
        e.stopPropagation();
        StorageService.setAvailableTokenIndicatorClosed(DateUtils.add(new Date(), 3, 'hour'));
        setIsOpened(false);
    };

    useEffect(() => {
        const expire = StorageService.getAvailableTokenIndicatorClosed();
        if (expire && DateUtils.diff(new Date(), expire) < 0) {
            return;
        }

        if (couponTotal > 0) {
            setIsOpened(true);
        }
    }, [couponTotal]);

    if (!isOpened) {
        return null;
    }

    return <Component onClose={handleClose} {...props} />;
};

export default AvailableCouponIndicator;
